  import React, { useContext, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { GameContext } from "../App";
  import CardList from "../components/cardList";
  import Cardbacks from "../components/cardBackComponent";
  import cardBacks from "../cardBacks/cardBacks.json";
  import PointImage from "../background-images/pointLogo.png";
  import authHook from "../customHooks/authHook";

  const Store = () => {
    // Ensure user is authenticated
    authHook();

    // Pull relevant data from context
    const { user, socket } = useContext(GameContext);

    // Local state management
    const [activeTab, setActiveTab] = useState("CardsTab");
    const [showPointsModal, setShowPointsModal] = useState(false);

    const navigate = useNavigate();

    // Navigate to a specific card's details
    const focusCard = (card) => {
      navigate("/store/card/" + card.id);
    };

    // Toggle the modal that displays Stripe buy buttons
    const togglePointsModal = () => {
      setShowPointsModal((prev) => !prev);
    };

    // Send buy request for a card back
    const buyCardback = (cardBack) => {
      socket.current.emit("buy-card-back", { email: user.email, cardBack });
    };

    return (
      <div className="store-wrapper">
        {/* ────────────── HEADER/BANNER ────────────── */}
        <header className="store-banner">
          <h1 className="banner-title">Welcome to the Card Store</h1>
          <p className="banner-subtitle">
            Purchase new card designs, collect unique card backs,
            <br />
            and earn more points to expand your collection!
          </p>
          <div className="user-points-info">
            {/* Example of showing user points if available */}
            {user && (
            <div className="pointDisplay storePointsOnPage">
            <img className="pointImg" src={PointImage} alt="pointLogo" />
            <div className="pointsNumber">
            <strong>Your Points: </strong>
            {user.displayPoints ? `${user.displayPoints}` : "0"}
      </div>
    </div>
  )}
          </div>
        </header>

        {/* ────────────── NAVIGATION TABS ────────────── */}
        <nav className="tabs-nav">
          <ul className="tabs-list">
            <li
              className={`tab-item ${activeTab === "CardsTab" ? "active" : ""}`}
              onClick={() => setActiveTab("CardsTab")}
            >
              Cards
            </li>
            <li
              className={`tab-item ${
                activeTab === "CardBackTab" ? "active" : ""
              }`}
              onClick={() => setActiveTab("CardBackTab")}
            >
              Card Backs
            </li>
          </ul>
        </nav>

        {/* ────────────── MAIN CONTENT ────────────── */}
        <div className="store-content">
          {/* BUY POINTS BUTTON (ALWAYS ACCESSIBLE) */}
          <div className="buy-points-area">
            <button className="cta-button" onClick={togglePointsModal}>
              Buy Points
            </button>
          </div>

          {/* CARDS TAB */}
          {activeTab === "CardsTab" && (
            <div className="cards-tab-content">
              <h2 className="tab-heading">Browse & Purchase Card Designs</h2>
              <p className="tab-description">
                Click a card to view more details, or to purchase.
              </p>
              <CardList onCardClick={focusCard} />
            </div>
          )}

          {/* CARD BACKS TAB */}
          {activeTab === "CardBackTab" && (
            <div className="card-backs-tab-content">
              <h2 className="tab-heading">Choose a Card Back</h2>
              <p className="tab-description">
                Pick from a variety of styles to customize your deck.
              </p>

              {/* Grid layout of card backs */}
              <div className="card-back-grid">
                {cardBacks.map((cardBack, index) => {
                  const owned =
                    user?.owned?.[cardBack] || cardBack === "defaultCardBack";

                  return (
                    <div key={index} className="card-back-tile">
                      <div className="card-back-image">
                        <Cardbacks cardBack={cardBack} />
                        {owned && cardBack !== "defaultCardBack" && (
                          <div className="owned-overlay">Owned</div>
                        )}
                      </div>

                      {/* Display purchase option if not owned */}
                      {!owned && (
                        <div
                          className="point-cost-button"
                          onClick={() => buyCardback(cardBack)}
                        >
                          <img
                            className="point-img-icon"
                            src={PointImage}
                            alt="Points"
                          />
                          <span className="cost-text">100</span>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>

        {/* ────────────── MODAL FOR BUYING POINTS ────────────── */}
        {showPointsModal && (
          <div className="modal-backdrop">
            <div className="modal-content">
              <button className="close-modal-btn" onClick={togglePointsModal}>
                ✕
              </button>
              <h3>Purchase Points</h3>
              <p>Select a bundle below to add points to your account.</p>
              <div className="stripe-buttons-container">
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qaaI8Giu6hwH7QIf7CqCp"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7S0NI8Giu6hwH7ExPk0GcP"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qqSI8Giu6hwH7y2OVVul0"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qr8I8Giu6hwH7CgL7tTpq"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qrmI8Giu6hwH7DhoD2OMb"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qsGI8Giu6hwH7Hb3UJxGz"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
                <stripe-buy-button
                  buy-button-id="buy_btn_1O7qv5I8Giu6hwH7jmdqwu9S"
                  publishable-key="pk_test_51O7QyWI8Giu6hwH7vpL2EFUEpQkyPekQOVQ4JBgzspJXbiUd8h7DoiK8CORRoT3IZklvZJAPxOrhOPGcMe4Ko0UV008iHo0EXX"
                  customer-email={user?.email}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  export default Store;