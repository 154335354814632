import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

import "../styles/chart.css"; // or wherever you keep chart.css

function Chart(props) {
  const chartData = () => {
    const data = [
      { manaCost: 0, minions: 0, spells: 0 },
      { manaCost: 1, minions: 0, spells: 0 },
      { manaCost: 2, minions: 0, spells: 0 },
      { manaCost: 3, minions: 0, spells: 0 },
      { manaCost: 4, minions: 0, spells: 0 },
      { manaCost: 5, minions: 0, spells: 0 },
      { manaCost: 6, minions: 0, spells: 0 },
      { manaCost: 7, minions: 0, spells: 0 },
      { manaCost: 8, minions: 0, spells: 0 },
      { manaCost: 9, minions: 0, spells: 0 },
      { manaCost: "10+", minions: 0, spells: 0 }
    ];

    const allCards = [...props.cards];
    for (let i = 0; i < allCards.length; i++) {
      const card = allCards[i];
      if (card.cost >= 10 && card.type === "spell") {
        data[10].spells++;
      } else if (card.cost >= 10) {
        data[10].minions++;
      } else if (card.type === "spell") {
        data[card.cost].spells++;
      } else if (card.type === "creature" || card.type === "spellcaster") {
        data[card.cost].minions++;
      }
    }
    return data;
  };

  const data = chartData();
  const maxCardCount = data.map((item) => item.minions + item.spells);
  const max = Math.max(...maxCardCount);

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="chart-tooltip">
          <p className="chart-tooltip-label">Mana Cost: {label}</p>
          <p className="chart-tooltip-minions">
            Minions: {payload[0].payload.minions}
          </p>
          <p className="chart-tooltip-spells">
            Spells: {payload[0].payload.spells}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="chart-container">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data}>
          <defs>
            <linearGradient id="minionsGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="100%" stopColor="#ba7c37" stopOpacity={0.9} />
            </linearGradient>
            <linearGradient id="spellsGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="100%" stopColor="#a0601f" stopOpacity={0.9} />
            </linearGradient>
          </defs>

          <CartesianGrid stroke="#444" strokeDasharray="3 3" />
          <XAxis dataKey="manaCost" tick={{ fill: "#f1f1f1" }} />
          <YAxis
            type="number"
            domain={[0, max]}
            tickCount={max + 1}
            tick={{ fill: "#f1f1f1" }}
          />
          <Tooltip content={renderTooltip} />
          <Legend wrapperStyle={{ color: "#f1f1f1" }} />

          <Bar
            dataKey="minions"
            name="Minions"
            fill="url(#minionsGradient)"
            stackId="a"
            radius={[5, 5, 0, 0]}
            isAnimationActive={true}
          />
          <Bar
            dataKey="spells"
            name="Spells"
            fill="url(#spellsGradient)"
            stackId="a"
            radius={[5, 5, 0, 0]}
            isAnimationActive={true}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;