
import { Link } from "react-router-dom";
import Card from "../components/card";
import Carousel from "./global/carousel"


function statsDisplay({ stats, cards, user }) {
  const statsCopy = [...stats];

  const cardHighestWinrates = () => {
    return statsCopy.sort((a, b) => {
      const winrateA = a.win === 0 ? 0 : a.win / (a.win + a.loss);
      const winrateB = b.win === 0 ? 0 : b.win / (b.win + b.loss);
      if (winrateA > winrateB) return -1;
      if (winrateA < winrateB) return 1;
      // If win rates are equal, sort by total times played in descending order
      return b.totalTimesPlayed - a.totalTimesPlayed;
    });
  };

  const cardLowestWinrates = () => {
    return statsCopy.sort((a, b) => {
      const winrateA = a.win === 0 ? 0 : a.win / (a.win + a.loss);
      const winrateB = b.win === 0 ? 0 : b.win / (b.win + b.loss);
      if (winrateA > winrateB) return -1;
      if (winrateA < winrateB) return 1;
      // If win rates are equal, sort by total times played in ascending order
      return a.totalTimesPlayed - b.totalTimesPlayed;
    });
  };

  const playRateOfCards = () => {
    return statsCopy.sort((a, b) => b.totalTimesPlayed - a.totalTimesPlayed);
  };

  const topFive = cardHighestWinrates().slice(0, 5);
  const botFive = cardLowestWinrates().slice(cardLowestWinrates().length - 5);
  const cardPlayRates = playRateOfCards().slice(0, 5);
  const allCardsWinrates = cardLowestWinrates();

  const unplayedCards = cards.filter(
    (card) => !stats.find((statCard) => statCard.card === card.name)
  );

  function getCardByName(name) {
    return cards.find((allCards) => allCards.name === name);
  }

  return (
    <div className="stats-wrapper">
      {/* Banner area at top */}
      <header className="stats-banner">
        <h1 className="stats-banner-title">Your Card Stats & Analysis</h1>
        <p className="stats-banner-subtitle">
          Dive into performance metrics, highlight strengths, and find hidden gems.
        </p>
      </header>

      <main className="stats-content">
        {/* Top 5 Cards */}
        <section className="stats-section">
          <h2 className="stats-section-title">Top 5 Cards</h2>
          <div className="stats-card-grid">
            {topFive.map((cardStats) => (
              <div key={`top-${cardStats.card}`} className="stats-card-tile">
                <Link to={"/cards/" + getCardByName(cardStats.card)?.id}>
                  <Card
                    id="card"
                    card={getCardByName(cardStats.card)}
                    cardStyle={user.usersettings?.[cardStats.card]?.style}
                  />
                </Link>
                <div className="stats-card-info">
                  <div className="stats-winrate">
                    {cardStats.win === 0
                      ? "0%"
                      : cardStats.loss === 0
                      ? "100%"
                      : `${Math.round(
                          (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                        )}%`}
                  </div>
                  <div className="stats-played">Played: {cardStats.totalTimesPlayed}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Bottom 5 Cards */}
        <section className="stats-section">
          <h2 className="stats-section-title">Bottom 5 Cards</h2>
          <div className="stats-card-grid">
            {botFive.map((cardStats) => (
              <div key={`bot-${cardStats.card}`} className="stats-card-tile">
                {console.log(cardStats)}
                <Link to={"/cards/" + getCardByName(cardStats.card)?.id}>
                  <Card
                    id="card"
                    card={getCardByName(cardStats.card)}
                    cardStyle={user.usersettings?.[cardStats.card]?.style}
                  />
                </Link>
                <div className="stats-card-info">
                  <div className="stats-winrate">
                    {cardStats.win === 0
                      ? "0%"
                      : cardStats.loss === 0
                      ? "100%"
                      : `${Math.round(
                          (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                        )}%`}
                  </div>
                  <div className="stats-played">Played: {cardStats.totalTimesPlayed}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Most Played Cards */}
        <section className="stats-section">
          <h2 className="stats-section-title">Most Played Cards</h2>
          <div className="stats-card-grid">
            {cardPlayRates.map((cardStats) => (
              <div key={`played-${cardStats.card}`} className="stats-card-tile">
                <Link to={"/cards/" + getCardByName(cardStats.card)?.id}>
                  <Card
                    id="card"
                    card={getCardByName(cardStats.card)}
                    cardStyle={user.usersettings?.[cardStats.card]?.style}
                  />
                </Link>
                <div className="stats-card-info">
                  <div className="stats-winrate">
                    {cardStats.win === 0
                      ? "0%"
                      : cardStats.loss === 0
                      ? "100%"
                      : `${Math.round(
                          (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                        )}%`}
                  </div>
                  <div className="stats-played">Played: {cardStats.totalTimesPlayed}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* All Cards */}
        <section className="stats-section">
          <h2 className="stats-section-title">All Cards</h2>
          <div class="statCarouselContainer">
            <Carousel>
            {allCardsWinrates.map((cardStats) => (
              <div key={`all-${cardStats.card}`} className="stats-card-tile">
                <Link to={"/cards/" + getCardByName(cardStats.card)?.id}>
                  <Card
                    id="card"
                    card={getCardByName(cardStats.card)}
                    cardStyle={user.usersettings?.[cardStats.card]?.style}
                  />
                </Link>
                <div className="stats-card-info">
                  <div className="stats-winrate">
                    {cardStats.win === 0
                      ? "0%"
                      : cardStats.loss === 0
                      ? "100%"
                      : `${Math.round(
                          (cardStats.win / (cardStats.win + cardStats.loss)) * 100
                        )}%`}
                  </div>
                  <div className="stats-played">Played: {cardStats.totalTimesPlayed}</div>
                </div>
              </div>
            ))}
            </Carousel>
            </div>
        </section>

        {/* Unplayed Cards */}
        <section className="stats-section">
          <h2 className="stats-section-title">Unplayed Cards</h2>
          <div class="statCarouselContainer">
            <Carousel>
            {unplayedCards
              .filter((card) => !card.token)
              .map((card) => (
                <div key={`unplayed-${card.id}`} className="stats-card-tile">
                  <Link to={"/cards/" + card.id}>
                    <Card
                      id="card"
                      card={card}
                      cardStyle={user.usersettings?.[card.name]?.style}
                    />
                  </Link>
                </div>
              ))}
            </Carousel>
          </div>
        </section>
      </main>
    </div>
  );
}

export default statsDisplay;