import { Nav, NavLink, NavMenu } from "./NavbarElements";
import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GameContext } from "../../App";
import PointImage from "../../background-images/pointLogo.png";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";

const Navbar = () => {
  const {
    user,
    signedIn,
    socket,
    isMuted,
    setIsMuted,
    showNavbar,
    setShowNavbar,
    playersOnline,
    setDisplayGlossary
  } = useContext(GameContext);

  const location = useLocation();
  const navigate = useNavigate();

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen().catch((err) => {
          console.error(`Error attempting to exit full-screen mode: ${err.message}`);
        });
      }
    }
  };

  const toggleMute = () => {
    setIsMuted((prevState) => {
      if (!prevState) {
        // Mute the tab
        document.documentElement.muted = true;
      } else {
        // Unmute the tab
        document.documentElement.muted = false;
      }
      return !prevState;
    });
  };

  const checkFullScreen = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", checkFullScreen);
    return () => {
      document.removeEventListener("fullscreenchange", checkFullScreen);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <span className="volumeToggle" onClick={toggleMute}>
        {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
      </span>

      {/* Only show fullscreen toggle if window is large enough */}
      {!(windowSize.width <= 799 || windowSize.height <= 599) && (
        <span className="fullScreenToggle" onClick={toggleFullScreen}>
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </span>
      )}

      {(socket?.current?.activeGame ||
        windowSize.width <= 799 ||
        windowSize.height <= 599) && (
        <div
          className={`gameFontColors showNavbarToggle ${
            showNavbar ? "active" : ""
          }`}
          onClick={() => setShowNavbar((prev) => !prev)}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}

      {(!socket?.current?.activeGame || showNavbar) && (
        <h1 className="gameFontColors playersOnline">Players Online: {playersOnline}</h1>
      )}

      {(!socket?.current?.activeGame || showNavbar) && (
        <Nav id={showNavbar ? "displayNavBarPhone" : ""}>
          <NavMenu>
            <NavLink to="https://discord.gg/zzvEKu6Es5" target="_blank">
              Discord
            </NavLink>
            <span
              onClick={() => setDisplayGlossary((prev) => !prev)}
              className="glossaryButton"
            >
              ?
            </span>
            {signedIn && (
              <>
                {location.pathname === "/play" ? (
                  <div onClick={() => navigate("/reload")}>
                    <NavLink>Play</NavLink>
                  </div>
                ) : (
                  <NavLink to="/play">Play</NavLink>
                )}
                <NavLink to="/deckbuilder">Deck Builder</NavLink>
                <NavLink to="/contact">Contact</NavLink>
              </>
            )}
            <NavLink to={signedIn ? "/profile" : "/"}>
              {signedIn ? "Profile" : "Authentication"}
            </NavLink>
            {signedIn && <NavLink to="/store">Store</NavLink>}
          </NavMenu>

          {/* 
            Hide points if on the store page or any /store sub-route:
            Checks if the path begins with "/store".
          */}
          {signedIn && !location.pathname.startsWith("/store") && (
            <div className="pointDisplay">
              <img className="pointImg" src={PointImage} alt="pointLogo" />
              <div className="pointsNumber">
                {user.displayPoints ? `${user.displayPoints}` : "0"}
              </div>
            </div>
          )}
        </Nav>
      )}
    </>
  );
};

export default Navbar;